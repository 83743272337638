<template>
  <div>
    <ImportListCardV2 :product="product" :key="product.id" />
  </div>
</template>

<script>
import ImportListCardV2 from "@/components/ImportListCardV2.vue";
import RetailerProducts from "@/services/RetailerProducts";
export default {
  components: {
    ImportListCardV2,
  },
  data() {
    return {
      product: {},
      thumbs: [],
      normal_sizes: [],
      showImages: false,
      variants: "",
    };
  },
  methods: {
    getProduct(id) {
      RetailerProducts.getProduct(id)
        .then((response) => {
          this.product = response.data;

          const id = this.product.id;
          this.getProductVariants(id);
          this.i = 1;
          for (const val of response.data.productImages) {
            this.i = this.i + 1;
            this.thumbs.push({
              id: this.i,
              url: val.thumbnailImage,
            });
            this.normal_sizes.push({
              id: this.i,
              url: val.image,
            });
          }
          this.showImages = true;
          this.images = {
            thumbs: this.thumbs,
            normal_size: this.normal_sizes,
            large_size: this.normal_sizes,
          };

          console.log("this.product", this.product);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getProductVariants(id) {
      RetailerProducts.getProductVariants(id)
        .then((response) => {
          this.variants = response.data.products;
          console.log(" this.variants", this.variants);
          if (this.variants.length >= 1) {
            this.product.variants = this.variants;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getProduct(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
